import React from "react"
/** @jsx jsx */
import { Box, Grid, jsx } from "theme-ui"
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import HeaderBlockColor from "../components/headerBlockColor"
import NewsItemCard from "../components/newsItemCard"

const NewsPage = props => {
  const data = useStaticQuery(graphql`
    query NewsListQuery {
      allStrapiNewsItem(sort: { fields: date, order: DESC }) {
        edges {
          node {
            title
            date
            dateFormatted: date(formatString: "MMMM DD, YYYY")
            content
            blurb
            slug
            img {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            img_alt
            news_categories {
              tag
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Latest News" />
      <HeaderBlockColor />
      <Box
        sx={{
          mx: 4,
          "& > h1": { fontSize: 6, mb: 2 },
          "& .bodyCopy": { fontSize: 3, color: "muted", mb: 4 },
        }}
      >
        <Box
          sx={{
            mx: 3,
            background:
              "linear-gradient(90deg, rgba(244,5,88,1) 0%, rgba(226,30,54,1) 100%)",
            transform: "rotate(-3deg) skew(-3deg) !important",
            py: 3,
            px: 3,
            position: "relative",
            mt: "-100px",
            mb: 3,
            display: "inline-block",
            "& h1": {
              color: "white",
              transform: "rotate(3deg) skew(3deg) !important",
            },
          }}
        >
          <h1>Latest News</h1>
        </Box>
        <p className="bodyCopy">
          Checkout what is happening at Masterclass below or select a category
          link below a news item to see more on that subject. Members can log in
          to see member specific news.
        </p>
        <Grid gap={2} columns={[1, 2, 4]} sx={{ mb: 5 }}>
          {data.allStrapiNewsItem.edges.map((item, index) => {
            return <NewsItemCard item={item} key={`newsitem${index}`} />
          })}
        </Grid>
      </Box>
    </Layout>
  )
}

export default NewsPage
