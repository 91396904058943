/** @jsx jsx */
import { Box, Grid, jsx } from "theme-ui"
import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import { FaArrowRight } from "react-icons/fa"

const NewsItemCard = ({ item }) => {
  //static image query
  const data = useStaticQuery(graphql`
    {
      defaultImage: file(relativePath: { eq: "default-news-thumb.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const mainImg = item.node.img
    ? item.node.img.localFile.childImageSharp.fluid
    : data.defaultImage.childImageSharp.fluid
  const mainImgAlt = item.node.img_alt
    ? item.node.img_alt
    : "Default News Artwork"
  return (
    <Box className="newsItem" sx={styles}>
      <Link
        to={`/news/${item.node.slug}`}
        sx={{
          textDecoration: "none",
          "& h1": {
            fontSize: 4,
            mb: 0,
          },
          "& h2": {
            fontSize: 2,
            color: "muted",
            mt: [0, 1],
            mb: 1,
          },
        }}
      >
        <Box
          sx={{ bg: "transparent", p: 0, position: "relative" }}
          className="newsImage"
        >
          <Img fluid={mainImg} alt={mainImgAlt} sx={{ height: "300px" }} />
          <Box
            sx={{
              width: "50px",
              height: "50px",
              background: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              zIndex: 4,
              bottom: 0,
            }}
            className="arrow"
          >
            <FaArrowRight
              sx={{
                position: "relative",

                color: "accent",

                fontSize: 5,
              }}
            />
          </Box>
        </Box>
        <Box sx={{ p: 2, "& h1": { mt: 0 } }} className="newsDetails">
          <h1>{item.node.title}</h1>
          <h2>{item.node.dateFormatted}</h2>
        </Box>
      </Link>
      {item.node.news_categories &&
        item.node.news_categories.map((cat, index) => {
          return (
            <Link
              className="cat"
              to={`/news?category=${cat.tag}`}
              key={`cat-ling${index}`}
            >
              {cat.tag}
            </Link>
          )
        })}
    </Box>
  )
}
export default NewsItemCard

const styles = {
  "&.newsItem": {
    "a.cat": {
      color: "accent",
      pl: 2,
    },
    "& .arrow": {
      width: "50px",
      height: "50px",
      background: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      zIndex: 4,
      bottom: "0",
      transition: "background .3s ease",
      "& svg": {
        position: "relative",
        color: "accent",
        fontSize: 5,
      },
    },
    "& .newsImage": {
      position: "relative",
      overflow: "hidden",

      ".gatsby-image-wrapper": {
        transition: "all .3s ease",
      },
    },
    //focus targeting the element that can receive focus
    "&:hover, & > a:focus": {
      "& .newsImage": {
        ".gatsby-image-wrapper": {
          transform: "scale(1.2)",
        },
        "& .arrow": {
          background: "black",
        },
      },
    },
  },
}
